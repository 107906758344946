<template>
  <div class="worksManager">
    <div class="sctp-pad15">
      <el-button @click="goPage('/user/addoreditworks')" class="sctp-square" type="primary" size="small" icon="el-icon-plus">发布作品集</el-button>
      <el-button @click="batchDelete" class="sctp-square" size="small">删除选中</el-button>
    </div>
    <el-card class="box-card" shadow="never">
      <el-table
        @selection-change="selectionChange"
        :data="works"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="worksname"
          label="作品名"
          width="180">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true"
                         align="center"
                         height="100"
                         label="详细内容">
          <template slot-scope="scope">
            <div class="rich-text" style="width: 100px;max-height: 50px;overflow: hidden" v-html="scope.row.details"></div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="apkurl"
          label="应用">
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          label="创建时间">
          <template slot-scope="scope">
            {{ dateToString(scope.row.createtime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          label="修改时间">
          <template slot-scope="scope">
            {{ dateToString(scope.row.updatetime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作" width="120">
          <template slot-scope="scope">
            <div class="sctp-mar-b5">
              <router-link :to="'/user/addoreditworks/' + scope.row.id">
                <el-button
                  size="mini"
                >
                  编辑
                </el-button>
              </router-link>
            </div>
            <div class="sctp-mar-b5">
              <el-button
                size="mini" type="primary"
                @click="deleteWorks(scope.row.id)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="pageConfig.page"
          :page-size="pageConfig.pageSize"
          @size-change="pageConfig.handleSizeChange"
          @current-change="pageConfig.handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="pageConfig.total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: 'serviceIndex',
    data () {
      return {
        works: [],
        pageConfig: {
          page: 1,
          pageSize: 10,
          total: 0,
          handleSizeChange: (pageSize) => {
            this.pageConfig.pageSize = pageSize
            this.queryWorks()
          },
          handleCurrentChange: (page) => {
            this.pageConfig.page = page
            this.queryWorks()
          }
        }
      }
    },
    components: {
    },
    methods: {
      doBatchDelete (ids) {
        return this.$request.post({
          reqcode: '1128',
          reqdesc: '批量删除',
          ids: ids
        }).then(res => {
          this.$message.success('删除成功')
          this.queryWorks()
        })
      },
      batchDelete () {
        if (this.selectedItems.length === 0) {
          this.$message.warning('请至少选择一条数据')
        } else {
          this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
            this.doBatchDelete(this.selectedItems.map(item => item.id))
          })
        }
      },
      deleteWorks (id) {
        this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
          const ids = [id]
          this.doBatchDelete(ids)
        })
      },
      selectionChange (selectedArray) {
        this.selectedItems = selectedArray
      },
      queryWorks () {
        const { pageConfig: { page, pageSize } } = this
        this.$request.post({
          reqcode: '1127',
          reqdesc: '获取作品集',
          page: page,
          limit: pageSize,
          userid: this.user.userId
        }).then(res => {
          const { retdata = [], count = 0 } = res
          this.works = retdata
          this.pageConfig.total = count
        })
      }
    },
    computed: {},
    watch: {},
    beforeMount () {
      this.queryWorks()
    }
  }
</script>

<style scoped>
  /*@formatter:off*/
  .searchFrom::v-deep .el-form-item__content {width: 130px!important;}
  /*@formatter:on*/
</style>
<style lang="css">
  .el-tooltip__popper{max-width:50%}
</style>
